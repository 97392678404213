<div class="home">
    <h1>Secret santa</h1>
    <div class="body">
      <div class="content">
        <div
          v-for="(list, index) in lists"
          v-bind:key="index"
          class="list"
          v-bind:class="{ separator: index + 1 !== lists.length }"
        >
          <input v-on:keyup.enter="submit(list, $event)" placeholder="name" />
          <div class="people">
            <div class="line" v-for="(person, index) in list.people" v-bind:key="index">
              <p>
                {{ person.name }}
                <template v-if="person.giveTo">
                  <span class="arrow">&#8594;</span>
                  <span>{{ person.giveTo }}</span>
                </template>
              </p>
              <i class="material-icons remove" v-on:click="remove(list.people, _index)">close</i>
            </div>
          </div>
        </div>
      </div>

      <button v-on:click="assigment" :disabled="listAreEqual()">START ASSIGNMENT</button>
    </div>
  </div>