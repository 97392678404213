<template>
  <HomePage />
</template>

<script>
import HomePage from "./components/Home.vue";

export default {
  name: "App",
  components: {
    HomePage,
  },
};
</script>

<style lang="css" src="./App.css"></style>
